<template>
  <div>
    <div id="circleChart" style="width:580px;height:580px;"></div>
  </div>
</template>
<script>
import echarts from "echarts";
export default {
  props: {
    tiNameList: Array
  },
  data() {
    return {};
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
       if (
        this.tiNameList &&
        this.tiNameList.length
      ) {
        this.tiNameList.forEach(e => {
          e.name = e.level;
          e.value = e.percentCount;
        });
      }
      var circleChart = echarts.init(document.getElementById("circleChart"));
      window.onresize = circleChart.resize;
      window.addEventListener("resize", () => {
        circleChart.resize();
      });
      circleChart.setOption(
        {
           color: [
            "#39EFA5",
            "#0086FF",
            "#F8F560",
            "#F47053"
          ],
           tooltip: {
            transitionDuration: 0,
            show: false
          },
          graphic: [
            {
              type: "text", //通过不同top值可以设置上下显示
              left: "43%",
              top: "52%",
              style: {
                text: "总成绩",
                textAlign: "center",
                fill: "#fff", //文字的颜色
                width: 30,
                height: 30,
                fontSize: 34
              }
            }
          ],
          series: [
            {
              name: "等级",
              type: "pie",
              radius: ["35%", "45%"],
              center: ["52%", "55%"],
              selectedMode: "single",
              data:this.tiNameList,
              avoidLabelOverlap: false,
               itemStyle: {
                normal: {
                label: {
                  show: true,
                  formatter: '{per|{d}%}  ',
                 rich: {
                    per: {
                      fontSize: 28,
                      color: '#5cf2f',
                    }
                 }
                  },
                  labelLine: {
                    show: true,
                    length:30
                  }
                }
               },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        },
        true
      );
    }
  },
  watch: {
    tiNameList: {
      handler(newValue, oldValue) {
        this.initData();
      },
      deep: true
    }
  }
};
</script>