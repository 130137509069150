<template>
  <div class="app-container">
    <div class="top-wapper">
      <div class="left">
        <div class="school teacher-count">
          <div class="count">2300</div>
          <div class="grade-num">年级：{{gradeCount}}</div>
          <div class="class-num">班级：{{classCount}}</div>
          <vueSeamlessScroll :class-option="teacherListOptions" :data="teacherList"  class="teacher-list">
            <div class="list">
              <div class="teacher-img" v-for="(item,index) in teacherList" :key="index">
              <img  v-if='item.imgUrl' :src="item.imgUrl" alt="" style="width: 106px;height:106px;margin: 10px;" >
            </div>
        </div>
        </vueSeamlessScroll>
        </div>
        <div class="school item-classify">
          <div class="count hours-count">
            <div class="num">{{classArrange}}</div>
          </div>
          <div class="count attend-count">
             <div class="num">{{attendance}}</div>
          </div>
          <div class="count item-count">
             <div class="num">{{testItemCount}}</div>
          </div>
          <div class="count record-count">
             <div class="num">{{performanceCount}}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="total-result">
           <circleCharts :tiNameList='totalPoints'></circleCharts>
        </div>
        <div class="level">
          <div class="level-bg"></div>
          <div style="height:350px;width:100%;">
            <barCharts :dataList='dataList' :excellent='excellent' :good='good' :pass='pass' :noPass='noPass'/>
          </div>
        </div>
      </div>
    </div>
    <div class="center-wapper">
      <div class="classroom-result"  >
          <div class="student-info">
          <img :src="Student" alt="" style="width:62px" >
          <span style="margin-left:24px">{{courseList.name}}</span>
          <span style='margin-left:24px'>{{courseList.gradeName}}</span>
        </div>
        <div class="score">
          <span><span class="class-score"></span>班级得分</span>
          <span><span class="new-score"></span>最新得分</span>
        </div>
         <div class="course-list" v-for="(courseItem, courseIndex) in courseList.list" :key="courseIndex" v-show="courseIndex<4">
                  <div class="course-name">
                    {{ courseItem.itemName }}
                  </div>
                  <div class="progress">
                    <div class="number">
                      <div>{{ courseItem.score }}</div>
                      <span>{{ courseItem.classScore}}</span>
                    </div>
                    <div class="progress-change">
                      <div class="progress-wrapper">
                        <span class="progress1 progress-bg" :style="{ width: `${courseItem.score}%` }"></span>
                        <!-- <img :src="arrowBottom" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore > item.score
                        " class="arrow-bottom-img" />
                        <img :src="arrowTop" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore < item.score
                        " class="arrow-top-img" /> -->
                      </div>
                      <div class="progress-wrapper">
                        <span class="progress2 progress-bg" :style="{ width: `${courseItem.classScore}%` }"></span>
                        <!-- <img :src="arrowBottom" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore > item.lastHoleScore
                        " class="arrow-bottom-img" />
                        <img :src="arrowTop" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore < item.lastHoleScore
                        " class="arrow-top-img" /> -->
                      </div>
                    </div>
                  </div>
                  <div class="tested-total">测试次数:{{ courseItem.count }}次</div>
                  <div class="good">
                    历史最佳:
                    <span class="red"> {{ courseItem.bestScore }}分 </span>
                  </div>
                </div>
            <div v-show="!courseList.list.length" class="no-data">
            <img src="../assets/images/no_content.png" />
            <div style="color: #666">暂无数据</div>
      </div>
    </div>
      <div class="homework">
        <div class="homework-wapper">
          <vueSeamlessScroll :class-option="homeworkOptions" :data="homeworkList">
              <div class="homework-list" v-for="(item,index) in homeworkList " :key="index">
                <div class="list">
                   <div class="person-detail">
                    <img :src="Student" alt=""  class="studentImg">
                    <div class="detail">
                    <div style="color:#DBFEE8;font-size:24px;margin-top:4px">
                        <span class="name">{{item.userName}}</span>
                        <span class="className" style="margin-left:16px">{{item.gradeName}}</span>
                        </div>
                      <div style="color:#fff;margin-top:24px">{{item.time}}</div>
                    </div>
                  </div>
                  <div class="homework-cotent">{{item.content}}</div>
                  <div class="homework-img" >
                    <img :src="itemSrc" alt="" class="img" v-for='(itemSrc,index) in item.urlList' :key='index'>
                  </div>
                  </div>
              </div>
         </vueSeamlessScroll>
        </div>
      </div>
       <div class="ranking">
          <div style="margin-top: 140px">
                <span class="tiName">{{ stuList.tiName }}</span>
                <div class="list" v-for="(item, index) in stuList.list" :key="index" v-show="index<6" :class="'list'+index" >
                  <span class="stuName">{{ item.stuName }}</span>
                  <span class="result">{{ item.result }}</span>
                  <span class="school">{{ item.areaName }}</span>
                </div>
          </div>
        </div>
    </div>
    <div class="footer-wapper">
         <vueSeamlessScroll :class-option="classOptions" :data="classDetailList">
            <div class="list">
                <div class="item" v-for='(item,index) in classDetailList' :key="index">
                  <div class="score">{{item.score}}</div>
                  <div class="grade">{{item.name}}</div>
                </div>
            </div>
         </vueSeamlessScroll>
    </div>
  </div>
</template>
<script>
import {
  showSituation
} from "@/api/home";
import vueSeamlessScroll from "vue-seamless-scroll";
import circleCharts from "@/components/home/circleCharts";
import barCharts from '@/components/home/barCharts'
import Student from "@/assets/images/student.png";
import Man from "@/assets/images/man.png";
import woMan from "@/assets/images/woMan.png";
import $ from '@/assets/jquery-3.2.1.js'
export default {
  components: {
    circleCharts,
    barCharts,
    vueSeamlessScroll
  },
  computed: {
    classOptions(){
      return{
        step: 2.5, 
        limitMoveNum: 9, 
        direction: 2,
      }
    },
    teacherListOptions(){
      return{
        step: 2.5, 
        limitMoveNum: 10, 
        direction: 2,
      }
    },
    homeworkOptions() {
      return {
        step: 1.8,
        limitMoveNum: 2, 
        direction: 1, 
      };
    },
  },
  data() {
    return {
      Student,
      Man,
      woMan,
      homeworkList:[],
      classDetailList:[],
      result:[],
      totalItem:[], //分等级
      totalPoints:[],
      courseList:{},
      timer:0,
      listActive: 0,
      schoolTimer: 0,
      tiNameTimer:0,
      stuList:null,
      gradeCount:'',
      classCount:'',
      headCount:'',
      attendance:'', //今日出勤
      classArrange:'',//学期课时
      testItemCount:'',//校园项目数
      performanceCount:'',//校园成绩记录数
      teacherList:[],
      studentDetailList:[],
      itemList:[],
      dataList:[],
      excellent:[],
      good:[],
      pass:[],
      noPass:[],
      rankingList:[],
    };
  },
  created(){
    this.getShowSituation()
  },
  methods:{
    getShowSituation(){
      showSituation().then(res=>{
       if(res.code == 200){
          this.headCount = res.data.headCount,
          this.classCount = res.data.classCount
          this.gradeCount = res.data.gradeCount
          this.teacherList = res.data.teacherList
          this.attendance = res.data.attendance
          this.classArrange = res.data.classArrange
          this.testItemCount = res.data.testItemCount
          this.performanceCount = res.data.performanceCount
          this.homeworkList = res.data.homeWorkList
          this.classDetailList = res.data.classDetailList
          this.studentDetailList = res.data.studentDetailList
          this.totalItem = res.data.totalItem
          this.totalPoints = res.data.totalPoints
          this.list = res.data.list
          this.list.map(item=>{
            if(item.list.length || item.list.length>5){
              this.rankingList.push(item)
            }
          })
          for(var i=0,len=this.totalItem.length;i<len;i+=5){
            this.result.push(this.totalItem.slice(i,i+5));
          }
          this.handleDataList()
          this.handleData()
          this.handleLevel()
           // 等级
          setInterval(() => {
              this.handleLevel(this.timer++);
              if (this.timer >= this.result.length) {
                this.timer = 0;
              }
          }, 8000);

          // 课堂测试
          setInterval(() => {
              this.handleData(this.schoolTimer++);
              if (this.schoolTimer >= this.studentDetailList.length) {
                this.schoolTimer = 0;
              }
          }, 13000);
        }
        //  排行榜
        setInterval(() => {
          var _this = this
          var eleList = $(".ranking .list");
          eleList.removeClass('actives')
          _this.handleDataList( _this.listActive++);
           if ( _this.listActive >= _this.rankingList.length) {
                _this.listActive = 0;
            }
        },16000);
      })
    },
    // 处理排行榜数据
    handleDataList(index = 0){
      var dataList = []
      this.stuList = this.rankingList[index];
       $(document).ready(function(){
            var eleList = $(".ranking .list");
            $.each(eleList, function(i,ele) {
                var that = $(this);
                setTimeout(()=>{
                  that.addClass('actives');
                },200*i)
            });
        });
    },
     // 处理课堂测试数据
    handleData(idx = 0) {
      this.courseList = this.studentDetailList[idx]; 
      if(this.courseList.list.length<4){
        this.courseList.list.push(
          {
            itemName:"跳高",
            count:'5',
            score:'70',
            bestScore:'80',
            classScore:'75'
         },
         {
            itemName:'立定跳远',
            count:'2',
            score:'65',
            bestScore:'70',
            classScore:'67'
         },
        {
            itemName:'引体向上',
            count:'3',
            score:'90',
            bestScore:'95',
            classScore:'85'
         },
        {
            itemName:'仰卧起坐',
            count:'2',
            score:'75',
            bestScore:'80',
            classScore:'70'
         },
        )
      }
    },
    // 处理等级数据
    handleLevel(index = 0){
      var dataList = []
      var excellent = []
      var good = []
      var pass = []
      var noPass = []
      this.itemList = this.result[index];
       this.itemList.forEach((item1,index1)=>{
        dataList.push(item1.testItem)
        this.dataList = dataList
        item1.totalPoints.map((item2,index2)=>{
          if(item2.percentCount == 0){
            item2.percentCount = 15
          }
          if(item2.percentCount < 10){
            item2.percentCount += 5
          }
          if(index2 == 0){
           excellent.push(item2.percentCount)
           this.excellent = excellent
          }
          if(index2  == 1){
            good.push(item2.percentCount)
            this.good = good
          }
           if(index2 == 2){
            pass.push(item2.percentCount)
            this.pass = pass
          }
           if(index2 == 3){
            noPass.push(item2.percentCount)
            this.noPass = noPass
          }
        })
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.app-container {
  background: url("../assets/images/dataBg.png") center center no-repeat;
  background-size: 100% 100%;
  width: 3840px;
  height: 2160px;
  position: relative;
  .top-wapper{
    top:208px;
    height: 636px;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    .left{
      height: 636px;
      width: 2380px;
      margin-left:48px;
      display: flex;
      flex-direction: column;
      .teacher-count{
        height: 332px;
        width: 100%;
        background: url("../assets/images/school.png") center center
        no-repeat;
        background-size: 100% 100%;
        .count{
          font-size: 84px;
          color: #FAA54D;
          background: linear-gradient(360deg, #FFA74A 0%, #FFE973 100%, #FFE973 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: absolute;
          top: 160px;
          left: 150px;
          font-family: 'F';
        }
        .grade-num{
          position: absolute;
          top: 52px;
          left:764px;
          font-weight: bold;
          color: #DBFEE8;
          line-height: 33px;
          letter-spacing: 4px;
          font-size: 44px;
        }
        .class-num{
          position: absolute;
          top: 52px;
          left:1124px;
          font-weight: bold;
          color: #DBFEE8;
          line-height: 33px;
          letter-spacing: 4px;
          font-size: 44px;
        }
        .teacher-list{
          width:1850px;
          position: absolute;
          left: 504px;
          top:146px;
          height: 124px;
          overflow: hidden;
          .list{
            display: flex;
            justify-content: flex-start;
            .teacher-img{
              width: 126px;
              height: 126px;
              margin-left: 54px;
              background: linear-gradient(180deg, rgba(97, 240, 201, 0.52) 0%, rgba(97, 240, 201, 0.52) 100%);
              border: 0px solid rgba(50, 220, 152, 0.44);
          }
          }
          
        }
      }
      .item-classify{
        display: flex;
        justify-content: flex-start;
        margin-top:48px;
        .count{
          width:560px;
          height: 248px;
          font-size: 56px;
          color:#fff;
          font-weight: 500;
           background-size: 100% 100%;
           .hours-num{
              position: relative;
              top: 120px;
              left: 40%;
           }
           .num{
              position: relative;
              top: 120px;
              left: 50%;
           }
        }
        
        .hours-count{
          background: url("../assets/images/hours.png") center center
            no-repeat;
        }
        .attend-count{
          background: url("../assets/images/attend-count.png") center center
            no-repeat;
          margin-left:48px
        }
        .item-count{
           background: url("../assets/images/item-count.png") center center
            no-repeat;
          margin-left:48px
        }
        .record-count{
          background: url("../assets/images/record-count.png") center center
            no-repeat;
          margin-left:48px
        }
      }
    }
    .right{
      width:1308px;
      height:512px;
      margin-left:48px;
      display: flex;
      justify-content: flex-start;
      background: url("../assets/images/classroom-test.png") center center
        no-repeat;
      background-size: 100% 100%;
      padding-top:124px;
      .total-result{
        width:606px;
        height: 100%;
        margin-right:100px;
        background: url("../assets/images/total-result.png") center center
        no-repeat;
        background-size: 100% 100%;
        position: relative;
        .tiName{
          position: absolute;
          color: #fff;
          left: 180px;
          top: 8px;
          text-shadow: 0px 0px 6px #0cd2cb;
          font-size: 32px;
        }
      }
      .level{
        width:606px;
        height: 100%;
        .level-bg{
          background: url("../assets/images/level.png") center center
            no-repeat;
          height: 120px;
          background-size: 100% 100%;
          width:606px;
        }
      }
    }
  }
  .center-wapper{
    height: 904px;
    width:100%;
    bottom: 372px;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    .homework{
      width:1170px;
      height:780px;
       background: url("../assets/images/homework.png") center center
        no-repeat;
      background-size: 100% 100%;
      margin-left:44px;
      padding-top:124px;
      .homework-wapper{
        height:780px;
        overflow:hidden;
      }
      .homework-list{
        margin:14px 48px;
        .list{
          display: flex;
          flex-wrap: wrap;
          .person-detail{
            display: flex;
            justify-content: flex-start;
          .studentImg{
            width: 84px;
            height: 84px;
          }
          .detail{
            flex: 1;
            margin-left:24px
          }
         }
        }
        .homework-cotent{
          margin-top:24px;
          color: #fff;
          font-size: 24px;
          line-height: 40px;
          letter-spacing: 4px;
        }
        .homework-img{
          margin-top:24px;
          padding-bottom:24px;
          border-bottom:1px dashed #ccc;
          .img{
            width:150px;
            border-radius: 8px;
            border: 2px solid #FFFFFF;
            margin-right:28px;
          }
          .img:last-child{
            margin-right: 0;
          }
        }
        
      }
    }
     .ranking {
        width: 1308px;
        background: url("../assets/images/rankingList.png") center center
          no-repeat;
        background-size: 100% 100%;
        margin-left: 48px;
        .tiName {
          position: absolute;
          margin: -115px 450px;
          font-size: 34px;
          display: inline-block;
          width: 500px;
          text-align: center;
          color: #fff;
        }
        @keyframes action{
            0%{transform: translateX(240px);opacity: 0;}
            100%{ transform: translateX(0px);opacity: 1;margin-right:auto;}
        }
        .actives{animation:action 1s;opacity: 1;}
        .list0{
           background: url("../assets/images/1st.png") center center
          no-repeat;
        }
        .list1{
           background: url("../assets/images/2st.png") center center
          no-repeat;
        }
        .list2{
           background: url("../assets/images/3st.png") center center
          no-repeat;
        }
        .list3{
           background: url("../assets/images/four.png") center center
          no-repeat;
        }
        .list4{
           background: url("../assets/images/5st.png") center center
          no-repeat;
        }
         .list5{
           background: url("../assets/images/6st.png") center center
          no-repeat;
        }
        .list {
          overflow:hidden;
          opacity: 1;
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 922px;
          height: 104px;
          color: #fff;
          font-size: 32px;
          margin-top: 23px;
          padding-left: 340px;
          margin-left: 20px;
          .stuName{
            width: 200px;
            text-align: left;
          }
          .result {
            color: #faa54d;
            font-size: 40px;
            width: 280px;
            text-align: left;
          }
          .school{
            width: 400px;
            text-align: left;
          }
        }
      }
  }
  .footer-wapper{
    height: 246px;
    width: 3744px;
    position: absolute;
    bottom: 84px;
    display: flex;
    margin-left: 48px;
    background: url("../assets/images/gradeResult.png") center center
    no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .list{
      justify-content: flex-start;
      display: flex;
      margin-top:36px;
      .item{
        height: 174px;
        width:352px;
        margin-left: 64px;
        background: url("../assets/images/footer.png") center center
        no-repeat;
        background-size: 100% 100%;
        text-align: center;
        .score{
          font-size: 84px;
          font-family: 'F';
          margin-top:18px;
          color: #FAA54D;
          background: linear-gradient(360deg, #FFA74A 0%, #FFE973 100%, #FFE973 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .grade{
          font-size: 32px;
          color:#fff
        }
      }
    }
  }
}
 .classroom-result {
      background: url("../assets/images/classroom-test-big.png") center center
      no-repeat;
      background-size: 100% 100%;
      width:1170px;
      height: 100%;
      margin-left:44px;
      position: relative;
      .student-info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        right:48px;
        top:20px;
        color: #DBFEE8;
        font-size: 32px;
      }
        .score{
          margin-top:140px;
          font-size: 28px;
          color: #fff;
          margin-left:46px ;
          .class-score{
            width: 24px;
            height: 24px;
            background: #32DC98;
            display: inline-block;
            position: relative;
            top: 3px;
            right:10px;
          }
          .new-score{
            width: 24px;
            height: 24px;
            background: #87FFCF;
            display: inline-block;
            position: relative;
            top: 3px;
            right:10px;
            margin-left:46px;
          }
        }
        .course-list {
          font-size: 24px;
          display: flex;
          width: 1132px;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          cursor: pointer;
          color: #5aeef8;
          margin-top: 50px;
          text-align: left;
          &.active {
            background: #f5f7ff;
          }

          .course-name {
            width: 140px;
            text-align: left;
            margin-left: 10px;
            color: #faa54dff;
            margin-left: 46px;
            letter-spacing: 4px;
          }
          .tested-total {
            width: 200px;
            text-align: left;
            letter-spacing: 4px;
          }
          .good {
            width: 250px;
            letter-spacing: 4px;
            .red {
              color: #faa54dff;
              font-family: LucidaCalligraphy-Italic, LucidaCalligraphy;
            }
          }
          .progress {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            .number {
              display: inline-block;
              width: 65px;
              text-align: center;
            }
            .progress-change {
              width: 80%;
              box-sizing: border-box;
              padding-left: 15px;
              line-height: 40px;
              overflow: hidden;
              .progress-wrapper {
                width: 100%;
                height: 40px;
                float: left;
                overflow: hidden;
              }
              .arrow-bottom-img {
                position: relative;
                top: 2px;
                left: 3px;
              }
              .arrow-top-img {
                position: relative;
                top: 5px;
              }
              span.progress-bg {
                display: inline-block;
                height: 18px;
                position: relative;
                top: 3px;

                &.progress1 {
                  background-color: #32dc98;
                }
                &.progress2 {
                  background-color: #87ffcf;
                }
              }
              >>> .el-progress {
                .el-progress-bar__innerText {
                  color: transparent;
                }
              }
            }
          }
        }
        .no-data {
          margin: 50px auto;
          text-align: center;
        }
      }
</style>