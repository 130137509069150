<template>
  <div id="info">
    <!-- <canvas width="375" height="667" style="display: block; width: 375px; height: 667px;"></canvas> -->
  </div>

</template>


<script>
import * as THREE from '@/utils/three.module.js'
import { OrbitControls } from '@/utils/OrbitControls.js'
import { OBJLoader } from '@/utils/OBJLoader.js'
var camera, scene, renderer
var pivot5
var iNumber = 0
var CountMesh = 0
var DefaultColor = 0xa9a9a9
var rotateStart = new THREE.Vector3(0, 0, 0)
var panel
var renderEle
export default {

  data() {
    return {
    
    
    };
  },
  mounted(){
    this.cinitCava()
    },
  methods:{
     cinitCava() {
      let container = document.getElementById('info')
      console.log(container)
      let controls
      const scene = new THREE.Scene()
      const camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        2000
      )
      const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4)
      const light = new THREE.DirectionalLight(0xffffff, 1)
      const renderer = new THREE.WebGLRenderer()
      const loader = new OBJLoader()
      const textureLoader = new THREE.TextureLoader()

      camera.position.z = 50
      scene.add(ambientLight)
      scene.add(light)
      renderer.setClearColor(0x000000)
      renderer.setSize(window.innerWidth, window.innerHeight)

      container.appendChild(renderer.domElement)
      controls = new OrbitControls(camera, renderer.domElement)
      controls.enableZoom = false
      loader.load(
        'http://3dorbbec.oss-cn-shanghai.aliyuncs.com/19395_measure2020_12_15_15_53_42.obj?Expires=1926498178&OSSAccessKeyId=LTAI4Fpz4YZa9YnsrSvS5C56&Signature=ciR8mjG6%2FYXXqrHPktwH3PK3EZE%3D',
        onLoad,
        onProgress,
        onError
      )

      function onLoad(obj) {
        obj.scale.set(10, 10, 10)
        obj.traverse(function(child) {
          if (child instanceof THREE.Mesh) {
          }
        })

        scene.add(obj)
      }
      function onProgress(xhr) {
        if (xhr.lengthComputable) {
          const percentComplete = (xhr.loaded / xhr.total) * 100
          console.log(
            'model ' + Math.round(percentComplete, 2) + '% downloaded'
          )
        }
      }
      function onError(err) {}

      function render() {
        requestAnimationFrame(render)
        renderer.render(scene, camera)
      }
      render()
    }
  }
};
</script>

<style lang="scss" scoped>
.person-modal {
  width: 100%;
}
</style>
