<template>
   <div id="barChart" style="width:100%;height:350px;"></div>
</template>
<script>
import echarts from "echarts";
export default {
  props:{
    dataList:Array,
    good:Array,
    pass:Array,
    noPass:Array,
    excellent:Array
  },
  data(){
    return{

    }
  },
  mounted(){
    this.initbBarData()
  },
   methods: {
    initbBarData() {
      var barChart = echarts.init(document.getElementById("barChart"));
      window.onresize = barChart.resize;
      window.addEventListener("resize", () => {
        barChart.resize();
      });
       barChart.setOption(
        {
           color: [
            "#39EFA5",
            "#0086FF",
            "#F8F560",
            "#F47053"
          ],
           tooltip: {
        trigger: 'axis',
        axisPointer: {            
            type: 'shadow'
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
      splitLine: {
        show: false,
      },
      axisLabel: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
    },
    yAxis: {
        type: 'category',
        splitLine: {
          show: false,
        },
         axisLabel: {
          show: true,
          textStyle: {
            color: "#fff", 
            fontSize: 24, 
          },
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        data: this.dataList
        },
         series:[
            {
            name: '优秀',
            type: 'bar',
            stack: '总量',
            barWidth:35,
            data: this.excellent
          },
           {
            name: '良好',
            type: 'bar',
            stack: '总量',
            barWidth:35,
            // label: {
            //     show: false,
            //     position: 'insideRight',
            //      textStyle: {
            //       fontSize: 40
            //     }
            // },
            data: this.good
          },
           {
            name: '及格',
            type: 'bar',
            stack: '总量',
            barWidth:35,
            data: this.pass
          },
           {
            name: '不及格',
            type: 'bar',
            stack: '总量',
            barWidth:35,
            data: this.noPass
          },
         ]
        },
        true
      );
    },
    
     
  },
   watch: {
    dataList: {
      handler(newValue, oldValue) {
        this.initbBarData();
      },
      deep: true
    },
    excellent: {
      handler(newValue, oldValue) {
        this.initbBarData();
      },
      deep: true
    },
    pass: {
      handler(newValue, oldValue) {
        this.initbBarData();
      },
      deep: true
    },
    noPass: {
      handler(newValue, oldValue) {
        this.initbBarData();
      },
      deep: true
    },
  }
}
</script>