import axios from 'axios'
import router from '../router/index.js'
axios.defaults.timeout = 3000;
var http = axios.create({
    //   timeout: 3000
})

function get(url, data, baseUrl = '') {
    return new Promise((resolve, reject) => {
        http.get(`${url}`, {
                params: {
                    ...data
                }
            }).then(
                response => {
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
            .catch(error => {
                reject(error)
            })
    })
}

function post(url, data, baseUrl = '', timeout = 3000) {
    return new Promise((resolve, reject) => {
        http.post(`${url}`, data, {
                timeout: timeout
            }).then(
                response => {
                    resolve(response.data)
                },
                err => {
                    reject(err)
                }
            )
            .catch(error => {
                reject(error)
            })
    })
}

export {
    http,
    get,
    post
}